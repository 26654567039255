/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.background-primary {
  background-color: #e88f2a !important;
}
.mat-dialog-container {
  padding-bottom: 5px !important;
}
.mat-menu-panel {
  max-width: 330px;
}
